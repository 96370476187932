import React, {useEffect} from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {
  Change,
  Chat,
  Faq,
  HelperInfo,
  Join,
  JoinInfo,
  Login,
  Main,
  Modify,
  ModifyCertified,
  Mypage,
  PhoneCertified,
  PhoneLogin,
  Profile,
  Root,
  Setting,
  Term,
  Detail,
  Withdrawal,
  Report,
  Calculate,
  Kakao,
  Apple,
} from "../pages";
import { SWRConfig } from "swr";
import swrConfig from "../utils/swrConfig";
import { ThemeProvider } from "styled-components";
import { GlobalStyles } from "../styles/global-styles";
import HelmetComponent from "../components/HelmetComponent";
import WrapperRoute from "../components/routes/WrapperRoute";
import PrivateRoute from "../components/routes/PrivateRoute";
import theme from "../utils/theme";
import auth from "../utils/auth";
import {api} from "../utils/api";

const App = () => {

/*  useEffect(() => {
    const intervalId = setInterval(() => {
      if (auth.getLat() && auth.getLng()) {
        handleLocation();
      }
    }, 10000);

    return () => clearInterval(intervalId);
  }, []);


  const handleLocation = async () => {
    const formData = new FormData();
    formData.append("lat", String(auth.getLat()));
    formData.append("lng", String(auth.getLng()));

    const {
      data: { success, alert, data: data2 },
    } = await api.post("/helper/location/modify", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    if (success) {
    } else {
      console.log(alert);
    }
  };*/

  return (
    <SWRConfig value={swrConfig}>
      <ThemeProvider theme={theme}>
        <HelmetComponent />
        <GlobalStyles />
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<WrapperRoute element={<Root />} />} />
            {/* 카카로 로그인 */}
            <Route
              path="/app/login/kakao"
              element={<WrapperRoute element={<Kakao />} />}
            />
            <Route
              path="/app/login/apple"
              element={<WrapperRoute element={<Apple />} />}
            />
            {/* 로그인/회원가입 */}
            <Route
              path="/login"
              element={<WrapperRoute element={<Login />} />}
            />
            <Route
              path="/login/phone"
              element={<WrapperRoute element={<PhoneLogin />} />}
            />
            <Route
              path="/login/phone/:phone"
              element={<WrapperRoute element={<PhoneCertified />} />}
            />
            <Route
              path="/join/:phone"
              element={<WrapperRoute element={<Join />} />}
            />
            <Route
              path="/join/:phone/:alarm"
              element={<WrapperRoute element={<JoinInfo />} />}
            />
            <Route
              path="/join/:phone/:alarm/info"
              element={<WrapperRoute element={<HelperInfo />} />}
            />
            <Route
                path="/term/:type"
                element={<WrapperRoute element={<Term />} />}
            />
            <Route path="/faq" element={<WrapperRoute element={<Faq />} />} />
            {/* 메인 */}
            <Route path="/main" element={<PrivateRoute element={<Main />} />} />
            <Route
              path="/matching/:id"
              element={<PrivateRoute element={<Detail />} />}
            />
            {/* 채팅 */}
            <Route
              path="/chat/:id"
              element={<PrivateRoute element={<Chat />} />}
            />
            {/* 보고서 */}
            <Route
              path="/report/:id"
              element={<PrivateRoute element={<Report />} />}
            />
            {/* 마이페이지 */}
            <Route
              path="/mypage"
              element={<PrivateRoute element={<Mypage />} />}
            />
            <Route
              path="/calculate"
              element={<PrivateRoute element={<Calculate />} />}
            />
            <Route
              path="/profile"
              element={<PrivateRoute element={<Profile />} />}
            />
            <Route
              path="/profile/modify"
              element={<PrivateRoute element={<Modify />} />}
            />
            <Route
              path="/profile/modify/:phone"
              element={<PrivateRoute element={<ModifyCertified />} />}
            />
            <Route
              path="/profile/change/:phone"
              element={<PrivateRoute element={<Change />} />}
            />
            <Route
              path="/withdrawal"
              element={<PrivateRoute element={<Withdrawal />} />}
            />
            <Route
              path="/setting"
              element={<PrivateRoute element={<Setting />} />}
            />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </SWRConfig>
  );
};

export default App;
