import React, { useEffect } from "react";
import styled from "styled-components";
import { RouteType } from "../../types/types";
import { useSetRecoilState } from "recoil";
import { confirmModalState } from "../../utils/atom";
import useSWR from "swr";
import auth from "../../utils/auth";
import AlertModal from "../modal/AlertModal";
import { useNavigate } from "react-router-dom";
import TermModal from "../modal/TermModal";
import ConfirmModal from "../modal/ConfirmModal";
import SelectModal from "../modal/SelectModal";
import PostModal from "../modal/PostModal";
import TwoButtonModal from "../modal/TwoButtonModal";
import ToastModal from "../modal/ToastModal";
import CancleModal from "../modal/CancleModal";
import StartModal from "../modal/StartModal";
import Loading from "../elements/Loading";
import SampleModal from "../modal/SampleModal";

const PrivateRoute = ({ element, ...props }: RouteType) => {
  const navigate = useNavigate();

  const setConfirmModal = useSetRecoilState(confirmModalState);

  const { data, isLoading } = useSWR("/helper/mypage/info");

  useEffect(() => {
    if (data) return;

    // 로그인안되어있는 유저일 때
    setConfirmModal({
      text: "로그인이 필요한 페이지 입니다.",
      onConfirm: () => {
        auth.clearAppStorage();
        navigate("/login");
      },
    });
  }, [data]);

  if (isLoading) return <Wrapper></Wrapper>;
  if (!data || !auth.getToken())
    return (
      <Wrapper>
        <ConfirmModal />
      </Wrapper>
    );
  return (
    <Wrapper>
      {element}
      <Loading />

      <CancleModal />
      <StartModal />

      <AlertModal />
      <TwoButtonModal />
      <TermModal />
      <SelectModal />
      <PostModal />
      <ToastModal />
      <SampleModal />
    </Wrapper>
  );
};

export default PrivateRoute;

const Wrapper = styled.div`
  position: relative;
  max-width: 500px;
  width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;
  margin: auto;
  background: white;
`;
