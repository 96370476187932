import loadable from "@loadable/component";

export const Root = loadable(() => import("./Root"));

export const Kakao = loadable(() => import("./appLogin/Kakao"));
export const Apple = loadable(() => import("./appLogin/Apple"));

export const Login = loadable(() => import("./user/Login"));
export const PhoneLogin = loadable(() => import("./user/Phone"));
export const PhoneCertified = loadable(() => import("./user/Certified"));
export const Join = loadable(() => import("./user/Term"));
export const JoinInfo = loadable(() => import("./user/JoinInfo"));
export const HelperInfo = loadable(() => import("./user/HelperInfo"));

export const Main = loadable(() => import("./main/Main"));
export const Detail = loadable(() => import("./main/Detail"));

export const Chat = loadable(() => import("./Chat"));
export const Report = loadable(() => import("./Report"));

export const Mypage = loadable(() => import("./mypage/Mypage"));
export const Calculate = loadable(() => import("./mypage/Calculate"));
export const Term = loadable(() => import("./mypage/Term"));
export const Profile = loadable(() => import("./mypage/Profile"));
export const Modify = loadable(() => import("./mypage/Modify"));
export const ModifyCertified = loadable(
  () => import("./mypage/ModifyCertified")
);
export const Change = loadable(() => import("./mypage/Change"));
export const Withdrawal = loadable(() => import("./mypage/Withdrawal"));
export const Faq = loadable(() => import("./mypage/Faq"));
export const Setting = loadable(() => import("./mypage/Setting"));
