import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { useRecoilState } from "recoil";
import { sampleModalState } from "../../utils/atom";
import ModalWrapper from "./ModalWrapper";
import Button from "../elements/Button";
import { ButtonArea } from "../elements/Elements";

const SampleModal = () => {
  const [modal, setModal] = useRecoilState(sampleModalState);

  const wrapperRef = useRef(null);

  useEffect(() => {
    if (wrapperRef.current !== null) {
      setTimeout(() => {
        //@ts-ignore
        wrapperRef.current.style.bottom = "0px";
      });
    }
  }, [wrapperRef, modal]);

  const handleClose = () => {
    //@ts-ignore
    wrapperRef.current.style.bottom = "-100%";

    setTimeout(() => {
      setModal(null);
    }, 200);
  };

  if (modal === null) return null;

  return (
    <ModalWrapper position="bottom" onClose={handleClose}>
      <Wrapper ref={wrapperRef}>
        <PopupText>
          <h1
            dangerouslySetInnerHTML={{
              __html: modal?.type === "introduce" ? "자기소개 내용 예시" : "",
            }}
          />
          <p
            dangerouslySetInnerHTML={{
              __html:
                modal?.type === "introduce"
                  ? "작성하신 내용은 의뢰인들에게 노출되는 내용입니다.<br/>아래 예시를 참고해서 작성해 보세요."
                  : "",
            }}
          />
          <Sample>
            안녕하세요?<br/>
            저는 5년째 요양보호사 일을 하면서 시어머니 병원<br/>
            방문을 약 3년 동안 동행했어서 서울 대형병원 및 근<br/>
            처 약국 지리에 익숙합니다.<br/>
            부모님 병원방문은 병원동행 전문가인 저에게 맡겨<br/>
            주시고, 고객님은 직장에서 ‘서비스 결과 보고서’를<br/>
            받아보세요~
          </Sample>
        </PopupText>
        <ButtonArea>
          <Button onClick={handleClose}>확인</Button>
        </ButtonArea>
      </Wrapper>
    </ModalWrapper>
  );
};

export default SampleModal;

const Sample = styled.div`
  min-height: 109px;
  padding: 11px 14px;
  border-radius: 8px;
  border: solid 1px #e1e1e2;
  background: #efefef;
  margin-top: 6px;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: black;
`;

const PopupText = styled.div`
  padding: 24px 20px 10px;

  & > h1 {
    font-size: 18px;
    font-weight: 500;
    line-height: 25.2px;
    color: rgba(0, 0, 0, 0.8);
    margin-bottom: 6px;
  }

  & > p {
    font-size: 14px;
    font-weight: 400;
    line-height: 22.4px;
    color: rgba(0, 0, 0, 0.6);
  }
`;

const Wrapper = styled.div`
  width: 100%;
  background: #ffffff;
  border-radius: 24px 24px 0px 0px;
  position: relative;
  transition: all 0.3s;
  bottom: -100%;
`;
