import React from "react";
import styled from "styled-components";
import { RouteType } from "../../types/types";
import AlertModal from "../modal/AlertModal";
import TermModal from "../modal/TermModal";
import SelectModal from "../modal/SelectModal";
import PostModal from "../modal/PostModal";
import SampleModal from "../modal/SampleModal";
import Loading from "../elements/Loading";

const WrapperRoute = ({ element, ...props }: RouteType) => {
  return (
    <Wrapper>
      {element}
      <Loading />

      <AlertModal />
      <TermModal />
      <SelectModal />
      <PostModal />
      <SampleModal />
    </Wrapper>
  );
};

export default WrapperRoute;

const Wrapper = styled.div`
  position: relative;
  max-width: 500px;
  width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;
  margin: auto;
  background: white;
`;
