import React from "react";
import styled, { css } from "styled-components";

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  children: React.ReactNode | React.ReactNode[];
  disabled?: boolean;
  styleType?: "line" | "grayDisabled";
};

const Button = ({ children, disabled, styleType, ...props }: ButtonProps) => {
  return (
    <Wrapper className={styleType} disabled={disabled} {...props}>
      {children}
    </Wrapper>
  );
};

export default Button;

const Wrapper = styled.button`
  //크기 중간
  width: 100%;
  height: 48px;

  //기본
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  color: white;
  border-radius: 15px;
  background: ${(props) => props.theme.color.main};
  border: solid 1px ${(props) => props.theme.color.main};

  //선
  &.line {
    font-weight: 500;
    color: ${(props) => props.theme.color.gray1};
    background: white;
    border-color: ${(props) => props.theme.color.gray5};
  }

  &:disabled {
    color: white;
    background: #d8d8d8;
    border-color: #d8d8d8;
  }

  &.grayDisabled:disabled {
    color: white;
    background: #c4c4c4;
    border-color: #c4c4c4;
  }
`;
