import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import Button from "./Button";

import { ReactComponent as Back } from "../../assets/icons/back-icon.svg";
import { ReactComponent as Close } from "../../assets/icons/close-icon.svg";

const ButtonArea = styled.div`
  padding: 10px 20px 20px;

  &.flex {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > button {
      max-width: calc(50% - 5px);
    }
  }

  &.fixed {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: white;
  }

  &.small {
    padding: 10px 18px 18px;
  }

  &.big {
    padding: 23px 20px;
  }
`;

const MemberPage = styled.div`
  width: 100%;
  height: calc(100% - 54px - 78px);
  padding: 0 20px;
  overflow: auto;

  &.big {
    height: calc(100% - 54px - 96px);
  }
`;

const BackHeader = ({
  path,
  title,
  right,
  onClick,
}: {
  path?: string;
  title?: string;
  right?: string;
  onClick?: () => void;
}) => {
  const navigate = useNavigate();

  return (
    <HeaderWrapper>
      <Back onClick={() => (path ? navigate(path) : navigate(-1))} />
      {title && <p dangerouslySetInnerHTML={{ __html: title }}></p>}
      {right && onClick && (
        <button type="button" onClick={onClick}>
          {right}
        </button>
      )}
    </HeaderWrapper>
  );
};

const HeaderWrapper = styled.div`
  width: 100%;
  height: 54px;
  top: 0;
  left: 0;
  background: white;

  display: flex;
  align-items: center;

  & > button {
    position: absolute;
    white-space: nowrap;
    top: 19px;
    right: 20px;
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    color: #838383;
    text-decoration: underline;
  }

  & > p {
    width: 100%;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: -0.002em;
    color: #333333;

    & b {
      font-weight: 700;
    }

    & span {
      color: ${(props) => props.theme.color.main};
    }
  }

  & > svg {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 15px;
    left: 20px;
  }
`;

const CloseHeader = ({ path, title }: { path?: string; title?: string }) => {
  const navigate = useNavigate();

  return (
    <HeaderWrapper2>
      {title && <p dangerouslySetInnerHTML={{ __html: title }}></p>}
      <Close onClick={() => (path ? navigate(path) : navigate(-1))} />
    </HeaderWrapper2>
  );
};

const HeaderWrapper2 = styled.div`
  width: 100%;
  height: 54px;
  top: 0;
  left: 0;
  background: white;

  display: flex;
  align-items: center;

  & > p {
    width: 100%;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: -0.2%;
    color: #333333;
  }

  & > svg {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 15px;
    right: 20px;
  }
`;

const MemberTitle = (titleData: { title: string; subTitle?: string }) => {
  return (
    <MemberTitleBox>
      <h1
        className="memberTitle"
        dangerouslySetInnerHTML={{ __html: titleData.title }}
      ></h1>
      {titleData.subTitle === "" ? (
        ""
      ) : (
        <p className="memberSubTitle">{titleData.subTitle}</p>
      )}
    </MemberTitleBox>
  );
};

const MemberTitleBox = styled.div`
  & > h1 {
    font-size: 22px;
    font-weight: 500;
    line-height: 30.8px;
    letter-spacing: -0.2px;
    color: ${(props) => props.theme.color.gray1};
  }

  & > p {
    margin-top: 10px;
    font-size: 14px;
    font-weight: 400;
    line-height: 22.4px;
    letter-spacing: -0.2px;
    color: ${(props) => props.theme.color.gray6};
  }
`;

const MemberSection = styled.div`
  margin: 30px 0 0;

  & > label:nth-of-type(n + 2) {
    margin-top: 12px;
  }
`;

const OpacityButtonArea = ({
  text,
  button,
  onClick,
  disabled,
  className,
}: {
  text?: string;
  button: string;
  onClick: () => void;
  disabled?: boolean;
  className?: "gnb";
}) => {
  return (
    <OpacityArea className={className}>
      {text && <p>{text}</p>}
      <Button onClick={onClick} disabled={disabled}>
        {button}
      </Button>
    </OpacityArea>
  );
};

const OpacityArea = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(10px);
  padding: 20px;
  z-index: 1;

  & > p {
    font-size: 12px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: -0.2px;
    text-align: center;
    margin-bottom: 10px;
    color: #3a3a3a;

    &.gray {
      color: #707070;
    }
  }

  &.small {
    padding: 10px 20px 20px;
  }

  &.gnb {
    bottom: 68px;
  }
`;

const FormButton = ({
  button,
  subText,
  onClick,
  disabled,
}: {
  button: string;
  subText?: string;
  onClick: () => void;
  disabled?: boolean;
}) => {
  return (
    <FormButtonArea>
      <FormStyledButton onClick={onClick} disabled={disabled}>
        {button}
        {subText && !disabled && <p>{subText}</p>}
      </FormStyledButton>
    </FormButtonArea>
  );
};

const FormStyledButton = styled(Button)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-color: #d7d7d7;
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: -0.2px;
  color: white;

  & > p {
    margin-top: -5px;
    font-size: 10px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.2px;
    color: rgba(255, 255, 255, 0.8);
  }

  &:disabled {
    border-color: #d7d7d7;
    background: #d7d7d7;
  }
`;

const FormButtonArea = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 23px 20px;
`;

export {
  ButtonArea,
  MemberPage,
  MemberSection,
  BackHeader,
  CloseHeader,
  MemberTitle,
  OpacityButtonArea,
  FormButton,
  FormButtonArea,
  FormStyledButton,
  OpacityArea,
};
