import { atom } from "recoil";

type TwoButtonModalType = {
  title: string;
  text?: string;
  confirmText?: string;
  cancleText?: string;
  onConfirm?: () => void;
  onClose?: () => void;
} | null;

export const twoButtonModalState = atom<TwoButtonModalType>({
  key: `twoButtonModalState`,
  default: null,
});

type alertModalType = {
  title: string;
  text: string;
  onConfirm?: () => void;
} | null;

export const alertModalState = atom<alertModalType>({
  key: `alertModalState`,
  default: null,
});

type startModalType = {
  type: "start" | "end";
  id: string;
  mutate: () => void;
  onError: () => void;
} | null;

export const startModalState = atom<startModalType>({
  key: `startModalState`,
  default: null,
});

type cancleModalType = {
  id: string;
  mutate: () => void;
} | null;

export const cancleModalState = atom<cancleModalType>({
  key: `cancleModalState`,
  default: null,
});

type sampleModalType = {
  type: string;
} | null;

export const sampleModalState = atom<sampleModalType>({
  key: `sampleModalState`,
  default: null,
});

type confirmModalType = {
  text: string;
  onConfirm?: () => void;
} | null;

export const confirmModalState = atom<confirmModalType>({
  key: `confirmModalState`,
  default: null,
});

type termModalType = {
  type: string;
} | null;

export const termModalState = atom<termModalType>({
  key: `termModalState`,
  default: null,
});

type selectModalType = {
  title?: string;
  options: { value: string; text?: string }[];
  value?: string;
  onSelect?: (value: { value: string; text?: string }) => void;
} | null;

export const selectModalState = atom<selectModalType>({
  key: `selectModalState`,
  default: null,
});

type toastModalType = {
  text: string;
} | null;

export const toastModalState = atom<toastModalType>({
  key: `toastModalState`,
  default: null,
});

type PostModalType = {
  onClick: (address: string) => void;
} | null;

export const postModalState = atom<PostModalType>({
  key: `postModalState`,
  default: null,
});

type LoadingType = {
  show: boolean;
} | null;

export const loadingState = atom<LoadingType>({
  key: `loadingState`,
  default: null,
});
