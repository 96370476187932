import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useRecoilState } from "recoil";
import { cancleModalState } from "../../utils/atom";
import ModalWrapper from "./ModalWrapper";
import Button from "../elements/Button";
import { ButtonArea } from "../elements/Elements";
import Textarea from "../elements/Textarea";
import { api } from "../../utils/api";

const CancleModal = () => {
  const [modal, setModal] = useRecoilState(cancleModalState);

  const [content, setContent] = useState<string>("");

  const wrapperRef = useRef(null);

  useEffect(() => {
    if (wrapperRef.current !== null) {
      setTimeout(() => {
        //@ts-ignore
        wrapperRef.current.style.bottom = "0px";
      });
    }
  }, [wrapperRef, modal]);

  const handleClose = () => {
    //@ts-ignore
    wrapperRef.current.style.bottom = "-100%";

    setTimeout(() => {
      setModal(null);
    }, 200);
  };

  const handleCancle = async () => {
    const formData = new FormData();
    formData.append("matching_id", String(modal?.id));
    formData.append("reason", content);

    const {
      data: { success, alert, data: data2 },
    } = await api.post("/helper/matching/cancel", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    if (success) {
      handleClose();
      modal?.mutate && modal?.mutate();
    } else {
      console.log(alert);
    }
  };

  if (modal === null) return null;

  return (
    <ModalWrapper position="bottom" onClose={handleClose}>
      <Wrapper ref={wrapperRef}>
        <PopupText>
          <h1>서비스 취소 안내</h1>
          <p
            dangerouslySetInnerHTML={{
              __html:
                "서비스를 취소하시겠습니까?<br/>취소가 누적될 경우 패널티가 발생할 수 있습니다.",
            }}
          />
          <Textarea
            label="취소 사유 작성"
            placeholder="취소 사유를 최대한 상세하게 작성해주세요."
            value={content}
            onChange={(e) => setContent(e.currentTarget.value)}
          />
        </PopupText>
        <ButtonArea className="flex">
          <Button onClick={handleClose}>닫기</Button>
          <Button onClick={handleCancle}>서비스 취소</Button>
        </ButtonArea>
      </Wrapper>
    </ModalWrapper>
  );
};

export default CancleModal;

const PopupText = styled.div`
  padding: 24px 20px 10px;

  & > h1 {
    font-size: 18px;
    font-weight: 500;
    line-height: 25.2px;
    color: rgba(0, 0, 0, 0.8);
    margin-bottom: 6px;
  }

  & > p {
    font-size: 14px;
    font-weight: 400;
    line-height: 22.4px;
    color: rgba(0, 0, 0, 0.6);
    margin-bottom: 6px;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  background: #ffffff;
  border-radius: 24px 24px 0px 0px;
  position: relative;
  transition: all 0.3s;
  bottom: -100%;
`;
