import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useRecoilState } from "recoil";
import { startModalState } from "../../utils/atom";
import ModalWrapper from "./ModalWrapper";
import Button from "../elements/Button";
import { ButtonArea } from "../elements/Elements";
import { api } from "../../utils/api";
import Input from "../elements/Input";
import useSWR from "swr";

const StartModal = () => {
  const varUA = navigator.userAgent;
  const [modal, setModal] = useRecoilState(startModalState);

  const [code, setCode] = useState<string>("");
  const [btnChk, setBtnChk] = useState(true);

  const wrapperRef = useRef(null);

  const { data: mypage } = useSWR(`/helper/mypage/info`);

  useEffect(() => {
    if (wrapperRef.current !== null) {
      setTimeout(() => {
        //@ts-ignore
        wrapperRef.current.style.bottom = "0px";
      });
    }
  }, [wrapperRef, modal]);

  const handleClose = () => {
    //@ts-ignore
    wrapperRef.current.style.bottom = "-100%";
    setCode('')
    setTimeout(() => {
      setModal(null);
    }, 200);
  };

  const handleStart = async () => {
    const formData = new FormData();
    formData.append("matching_id", String(modal?.id));
    if (modal?.type === "start") formData.append("start_code", code);
    else formData.append("end_code", code);

    const {
      data: { success, alert, data: data2 },
    } = await api.post(
      modal?.type === "start"
        ? "/helper/matching/start/code/check"
        : "/helper/matching/end/code/check",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    if (success) {
      if (modal?.type === "start") {
        if (varUA.match("dolbau/Android") != null) {
          window.dolbau.startLocationTracking();
        } else if (varUA.match("dolbau/iOS") != null) {
          window.webkit.messageHandlers.setLocationTracking.postMessage(true);
        }

        setBtnChk(true)
      } else {
        if (varUA.match("dolbau/Android") != null) {
          window.dolbau.stopLocationTracking();
        } else if (varUA.match("dolbau/iOS") != null) {
          window.webkit.messageHandlers.setLocationTracking.postMessage(false);
        }
      }

      handleClose();
      modal?.mutate && modal?.mutate();
    } else {
      setBtnChk(true)
      modal?.onError && modal?.onError();
    }
  };

  if (modal === null) return null;

  return (
    <ModalWrapper
      position="bottom"
      onClose={() => handleClose()}
    >
      <Wrapper ref={wrapperRef}>
        <PopupText>
          <h1>서비스 {modal?.type === "start" ? "시작" : "완료"}코드 입력</h1>
          <p
            dangerouslySetInnerHTML={{
              __html:
                modal?.type === "start"
                  ? "방문해야 할 위치에 도착하셨나요?<br/>고객님 또는 부모님(어르신)에게 발송된 문자 내 서비스 코드를 입력하셔야 서비스가 시작됩니다."
                  : "서비스는 무사히 마치셨나요?<br/>정상적으로 서비스를 제공하지 않은 상태에서 완료하면<br/>패널티가 발생할 수 있습니다.",
            }}
          />
          <Input
            placeholder="서비스 코드 입력"
            value={code}
            onChange={(e) => setCode(e.currentTarget.value)}
          />
          <Notice>
            부모님(어르신)이 서비스 코드를 받으신 게 없다고 하신다면 고객님에게
            연락하셔서 확인해 주세요.
          </Notice>
        </PopupText>
        {modal?.type === "start" ? (
          <ButtonArea className="flex">
            <Button onClick={handleClose}>취소</Button>
            <Button
                onClick={() => {
                  if (btnChk === true) {
                    setBtnChk(false)
                    handleStart()
                  }
                }}
            >
              서비스 시작
            </Button>
          </ButtonArea>
        ) : (
          <ButtonArea>
            <Button
                onClick={() => {
                  if (btnChk === true) {
                    setBtnChk(false)
                    handleStart()
                  }
                }}
            >
              확인</Button>
          </ButtonArea>
        )}
      </Wrapper>
    </ModalWrapper>
  );
};

export default StartModal;

const Notice = styled.div`
  margin-top: 12px;
  font-size: 13px;
  font-weight: 400;
  line-height: 20.8px;
  color: ${(props) => props.theme.color.main};
`;

const PopupText = styled.div`
  padding: 24px 20px 10px;

  & > h1 {
    font-size: 18px;
    font-weight: 500;
    line-height: 25.2px;
    color: rgba(0, 0, 0, 0.8);
    margin-bottom: 6px;
  }

  & > p {
    font-size: 14px;
    font-weight: 400;
    line-height: 22.4px;
    color: rgba(0, 0, 0, 0.6);
    margin-bottom: 12px;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  background: #ffffff;
  border-radius: 24px 24px 0px 0px;
  position: relative;
  transition: all 0.3s;
  bottom: -100%;
`;
