import React, { useState } from "react";
import styled from "styled-components";
import { TextareaType } from "../../types/types";

const Textarea = ({
  label,
  errorText,
  placeholder,
  value,
  disable = false,
  readOnly = false,
  onChange,
  ...props
}: TextareaType) => {
  return (
    <Label>
      {label && <p>{label}</p>}
      <StyledTextarea
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        disabled={disable}
        readOnly={readOnly}
        {...props}
      />
      {errorText && errorText !== "" && <Error>{errorText}</Error>}
    </Label>
  );
};

export default Textarea;

const Error = styled.div`
  margin-top: 10px;
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;
  color: ${(props) => props.theme.color.red};
`;

const StyledTextarea = styled.textarea`
  width: 100%;
  height: 109px;
  padding: 11px 14px;
  border: solid 1px ${(props) => props.theme.color.gray4};
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  color: ${(props) => props.theme.color.gray3};
  resize: none;

  &::placeholder {
    color: #b5b5b9;
  }

  &.report {
    height: 140px;
    margin-top: 10px;
  }

  &.large {
    height: 200px;

    &::placeholder {
      color: rgba(181, 181, 185, 0.5);
    }
  }
`;

const Label = styled.label`
  position: relative;
  display: block;

  & > p {
    font-size: 14px;
    font-weight: 700;
    line-height: 26px;
    color: ${(props) => props.theme.color.gray3};
    margin-bottom: 10px;
  }
`;
