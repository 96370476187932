import React, { useState } from "react";
import styled from "styled-components";
import { InputType } from "../../types/types";

const Input = ({
  label,
  errorText,
  placeholder,
  type = "text",
  value,
  readOnly,
  onChange,
  btnText,
  btnDisable,
  onClick,
  time,
  id,
}: InputType) => {
  return (
    <Label>
      {label !== "" && <p>{label}</p>}
      <LineArea>
        <InputBox>
          <StyledInput
            id={id}
            type={type}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            readOnly={readOnly}
          />
          {time && time !== "" && <Time>{time}</Time>}
        </InputBox>
        {btnText && onClick && (
          <InputButton type="button" disabled={btnDisable} onClick={onClick}>
            {btnText}
          </InputButton>
        )}
      </LineArea>
      {errorText && errorText !== "" && <Error>{errorText}</Error>}
    </Label>
  );
};

export default Input;

const InputButton = styled.button`
  margin-left: 10px;
  width: 80px;
  min-width: 80px;
  height: 46px;
  border-radius: 8px;
  border: solid 1px ${(props) => props.theme.color.gray5};
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  color: ${(props) => props.theme.color.gray1};
`;

const Error = styled.div`
  margin-top: 10px;
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;
  color: ${(props) => props.theme.color.red};
`;

const Time = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 14px;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  white-space: nowrap;
  color: ${(props) => props.theme.color.main};
`;

const StyledInput = styled.input`
  width: 100%;
  height: 46px;
  border: solid 1px ${(props) => props.theme.color.gray4};
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  color: ${(props) => props.theme.color.gray3};
  padding: 0 14px;

  &::placeholder {
    color: rgba(13, 13, 14, 0.3);
  }

  &:read-only {
    background: #f8f8f8;
  }
`;

const InputBox = styled.div`
  width: 100%;
  position: relative;
`;

const LineArea = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const Label = styled.label`
  position: relative;
  display: block;

  & > p {
    font-size: 14px;
    font-weight: 700;
    line-height: 26px;
    color: ${(props) => props.theme.color.gray3};
    margin-bottom: 10px;
  }
`;
